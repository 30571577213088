import React, { useState } from "react";

const isMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
};

const ChatbotHeader = ({ design, botInfo, isAppearance }) => {
  const [currentWidth, setCurrentWidth] = useState(25);
  return (
    <div
      style={{
        width: "100%",
        height: "60px",
        padding: "20px 10px",
        background: design?.headerColor,
        borderRadius: design?.headerFooterBorderRadius,
        display: "flex",
        alignItems: "center",
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        position: "relative",
        color: design?.iconsColor || "white",
        borderBottomLeftRadius: Boolean(design?.transactionBoxBackground)
          ? "0px"
          : design?.headerFooterBorderRadius,
        borderBottomRightRadius: Boolean(design?.transactionBoxBackground)
          ? "0px"
          : design?.headerFooterBorderRadius,
        cursor: isAppearance ? "pointer" : null,
      }}
      onClick={() => {
        if (isAppearance) {
          window.parent.postMessage(
            JSON.stringify({
              type: "open_appearance_blocks",
              block: "generalTable",
            }),
            "*"
          );
        }
      }}
    >
      {Boolean(design?.headerLogo) &&
      design?.headerLogo !==
        "https://storage.botforce.ai/assets/bot-designer/thumbnail_Client-Logo-Placeholder.png" ? (
        <img
          src={design?.headerLogo}
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "50px",
            objectFit: "cover",
          }}
          alt="infotointell"
        />
      ) : null}
      {/* &nbsp;&nbsp;
            <span style={{ fontSize: "18px", fontWeight: "500" }}>
                {botInfo?.bot_name}
            </span> */}

      {!isMobile() ? (
        <div
          style={{
            position: "absolute",
            right: "50%",
            left: "50%",
            fontSize: "16px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          id="resizeChatbotWindow"
        >
          <div
            style={{ marginRight: "10px", cursor: "pointer" }}
            onClick={() => {
              if (currentWidth !== 100) {
                window.parent.postMessage(
                  `resize_window_${currentWidth + 25}`,
                  "*"
                );
                setCurrentWidth(currentWidth + 25);
              }
            }}
          >
            <span
              className="plus "
              style={{ color: "black", background: "white" }}
            >
              <i className="fas fa-plus"></i>
            </span>
          </div>
          <div>{`${currentWidth}%`}</div>
          <div
            style={{ marginLeft: "10px", cursor: "pointer" }}
            onClick={() => {
              if (currentWidth !== 25) {
                window.parent.postMessage(
                  `resize_window_${currentWidth - 25}`,
                  "*"
                );
                setCurrentWidth(currentWidth - 25);
              }
            }}
          >
            <span
              className="minus "
              style={{ color: "black", background: "white" }}
            >
              <i className="fas fa-minus"></i>
            </span>
          </div>
        </div>
      ) : null}

      <i
        className="fas fa-chevron-down chatbot-icons-btn"
        style={{
          position: "absolute",
          right: "10px",
          fontSize: "24px",
          cursor: "pointer",
        }}
        id="minimize"
        onClick={() => window.parent.postMessage("close", "*")}
      ></i>
    </div>
  );
};

export default ChatbotHeader;
