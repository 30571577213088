import React from "react";
import "../styles/standardCard.css";
import { eventDispatcher } from "../../../Scripts";

const BusinessCard = ({
    cardData,
    showTypingIndicator,
    isFixedImageSize,
    design,
    botInfo
}) => {

  

    return (
        <div
            className="standard-card"
            style={{
                borderRadius:design?.cardBorderRadius,
               
                boxShadow: cardData?.isMarketing ? `0px 0px 35px 10px ${design?.cardThemeColor}`: null
            }}
            
        >
            <div className="standard-card-img-cover">
                {
                    Boolean(cardData?.image_url) ?
                        <img
                            src={cardData?.image_url}
                            style={{ height: isFixedImageSize ? "200px" : "100%", maxHeight: "300px" }}
                        /> :
                        null
                }

                {
                    Boolean(cardData?.video_url) ?
                        <div style={{ width: "100%" }}>
                            <video
                                controls
                                autoPlay
                                muted
                                style={{ width: "100%" }}
                            >
                                <source 
                                src={cardData?.video_url}
                                ></source>
                            </video>
                          
                            {/* <iframe
                                src={cardData?.video_url}
                                style={{ width: "100%", margin: "auto", height: "250px" }}
                                allowFullScreen
                      
                                
                            /> */}


                        </div> : null
                }



            </div>
         

                 <div style={{width:'100%',display:"flex",alignItems:"center",marginBottom:"10px",padding:"10px", background:design?.cardBackgroundColor}}>
                           
                           {
               Boolean(cardData?.qr_code) ?
               <div style={{width:"40%",height:"auto",display:"inline-block"}}>
                   <img src={cardData?.qr_code}  style={{width:"100%",objectFit:"cover"}}/>
                   <br/>
                   <p style={{fontSize:"10px", fontWeight:600,textAlign:"center"}}>Scan Me To Add To Contact</p>
               </div>
               :null
           }
            <div style={{width:"60%",height:"auto",display:"inline-block",padding:"10px",fontSize:"14px"}}>
                   <p style={{fontSize:"18px",textAlign:"left",fontWeight:600}}>{cardData?.businessInfo?.company_name}</p>
                   <br/>
                    <i className="fas fa-map-marker-alt"></i>&nbsp;{cardData?.heading}
                    <br/>
                    {cardData?.subheading}
                    <br/>
                    {
                        Boolean(cardData?.businessInfo?.email) ?
                        <><i className="fas fa-envelope"></i>&nbsp;&nbsp;<a href={`mailto:${cardData?.businessInfo?.email}`}>{cardData?.businessInfo?.email}</a>    <br/></>:null
                    }
                 <br/>
                 {
                        Boolean(cardData?.businessInfo?.phone) ?
                        <><i className="fas fa-phone"></i>&nbsp;&nbsp;<a href={`tel:${cardData?.businessInfo?.phone}`}>{cardData?.businessInfo?.phone}</a> </>:null
                    }
              
                    
               </div>
                         
                   
                       </div>

            <div style={{width:"100%",display:"flex",justifyContent:"center",marginBottom:"10px"}}>
                {
                    cardData?.actions?.map(action => 
                        <div 
                        style={{
                            display:"inline-block",
                            
                            width:"40px",
                            cursor:'pointer'
                        }}
                        onClick = {() => {
                            if (action.type === "postback") {
                                if (typeof action.postback === "object") {
                                    eventDispatcher(
                                        JSON.stringify({
                                            user_input: action.postback,
                                            bot_id: botInfo.bot_id
                                        })
                                    );
                                   
                                }
                                else {
                                    eventDispatcher(
                                        JSON.stringify({
                                            user_input: action.postback,
                                            bot_id: botInfo.bot_id
                                        })
                                    );
                                   
                                }
                                showTypingIndicator();
                            }
                            else {
                                window.open(action.url, "_blank");
                            }
                        }}
                    >
                           <img 
                                src={action.iconUrl} 
                                style={{
                                    width:"30px",
                                    height:"30px",
                                    borderRadius:"30px",
                                    objectFit:"cover",
                                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",            
                                }}
                                title ={action.label}
                            />
                    </div>   
                    )
                }
            </div>
           
   </div>
    );

}


export default BusinessCard;

