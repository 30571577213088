export default function getDateFromQueryString() {
  var url = new URL(decodeURI(window.location.href));
  var search_params = url.searchParams;

  return {
    bot_id: search_params.get("bot_id"),
    trigger: search_params.get("trigger"),
    old_user: search_params.get("old_user"),
    client_url: search_params.get("client_url"),
    is_appearance: search_params.get("is_appearance"),
    conversion: search_params.get("conversion"),
    hide_header: search_params.get("hide_header"),
  };
}
