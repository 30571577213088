import React from "react";
import "./style.css";
import Chatbot from "../../components/Chatbot";


const Home = () => {
  return <Chatbot />;
};

export default Home;
