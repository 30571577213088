import { livechatURL } from "../constants";

const closeSession = (data) => {
  return fetch(livechatURL.concat("closeuser"), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

const sendClientMessage = (data) => {
  return fetch(livechatURL.concat("sendclientmessage"), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export { closeSession, sendClientMessage };
