import React from "react";

import Button from "../../Button";
import { eventDispatcher } from "../../../Scripts";

const ar_regex = new RegExp("[\u0600-\u06ff]|[\u0750-\u077f]|[\ufb50-\ufc3f]|[\ufe70-\ufefc]");
const ReservationCard = ({
    cardData,
    isFixedImageSize,
    design,
    botInfo
}) => {

    return (
        <div
            className="standard-card"
            style={{borderRadius:design?.cardBorderRadius}}
        >
            <div className="standard-card-img-cover">
                <span className="product-price" style={{background:design?.cardThemeColor,color:design?.cardButtonFontColor}}>
                    <b>{cardData?.smoking_free ? "smooking is allowed" : "smooking is not allowed"}</b>
                </span>

                {
                    Boolean(cardData?.image_url) ?
                        <img
                            src={cardData?.image_url}
                            style={{ height: isFixedImageSize ? "200px" : "100%", maxHeight: "200px" }}
                        /> :
                        null
                }

            </div>

            <div
                className="standard-card-desc"
                style ={{ background:design?.cardBackgroundColor}}
            >
                {
                    Boolean(cardData?.room_name) ?
                        <div
                            className="standard-card-heading"
                            style={{
                                marginBottom: "10px",
                                textAlign: ar_regex.test(cardData?.room_name) ? "right" : "left"
                            }}
                        >
                            {cardData?.room_name}
                        </div>
                        : null
                }
                <Button
                    textColor={design?.cardButtonFontColor}
                    backgroundColor={design?.cardThemeColor}
                    label="Reserve"
                    customStyles={{ marginBottom: "5px" }}
                    onclick={() =>
                        
                        eventDispatcher(JSON.stringify({
                            user_input:{
                                function: "Reservation",
                                room_id: cardData.room_id,
                            },
                            bot_id: botInfo.bot_id
                        }))
                    }
                />
            </div>

            <div>
            </div>
        </div>
    )

}


export default ReservationCard;