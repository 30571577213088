
const IsJsonString = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };
export default function postMessagesListner (
    setDesign
) {
  window.addEventListener('message', function (e) {
   if(IsJsonString(e.data)){
       const data = JSON.parse(e.data);
       if(data.type === "update_appearance"){
        setDesign(data.design)
       }
   }
  });

}