import React from "react";
import "./style.css";

const TypingIndicator = ({
    design
}) => {
    
    return (
        <div  style={{width:"100%",display:"flex",alignItems:"center",animation: "fadeInLeft 200ms ease-in-out"}} >
        <div style={{width:"50px",display:"inline-block"}}>
            <img src={design?.botAvatarTyping || design?.botAvatar } style={{height:"40px",width:"40px",borderRadius:"40px"}}/>
        </div>
         <div 
            className="searchat-bot-message-bubble" 
            style={{
                display:"inline-block",
                background:design?.botBubbleBackground,
                borderRadius:design?.botBorderRadius,
                borderTopLeftRadius: design?.botBubbleNub ? "0px" : design?.botBorderRadius ,
                color: design?.botFontColor,
                fontSize:"16px"
            }}             
        >
          <div className="tidot" style={{ background: design?.botFontColor}} ></div>
          <div className="tidot" style={{ background: design?.botFontColor}} ></div>
          <div className="tidot" style={{ background: design?.botFontColor}} ></div>
        </div>
  </div>
        
    );
}

export default TypingIndicator;