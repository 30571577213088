import React, { useEffect } from "react";
import "../styles/ItemMenu.css";
import "../styles/button.css";
import "../styles/radios.scss"
import { eventDispatcher } from "../../../Scripts"



const ItemFormMenu = ({
    menuInfo,
    setMenuInfo,
    showTypingIndicator,
    design,
    botConfig,
    conversationID,
    setIncomingActivity,
    botInfo
}) => {

    useEffect(() => {
        document.documentElement.style.setProperty("--color-p", design?.cardThemeColor);
        document.documentElement.style.setProperty("--color-f", design?.cardButtonFontColor);

    }, []);


    const updateInfo = (entity, value) => {
        const menuInfoToUpd = { ...menuInfo };
        menuInfoToUpd[entity] = value;
        setMenuInfo(menuInfoToUpd);
    }





    return (
        <div
            className="searchat-chatbot-menu"
            style={{
                height: "auto",
                background: design?.menuBackgroundColor,
                color: design?.menuFontColor
            }}
        >
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: 'flex-end',
                    fontSize: "18px",
                    color: "gray"
                }}
            >
                <i
                    className="fas fa-window-close"
                    style={{ cursor: "pointer" }}
                    onClick={() => setMenuInfo(undefined)}
                ></i>
            </div>
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: 'center',
                    marginBottom: "10px"
                }}
            >
                <div style={{ width: '100%', textAlign: "center", fontWeight: "500" }}>
                    {botConfig?.quantityMessage}
                </div>
            </div>
            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: 'center', marginBottom: "20px" }}>
                <div style={{ width: '100%', textAlign: "center", fontWeight: "500" }}>
                    <div className="number">
                        <span
                            className="minus"
                            style={{
                                background: design?.menuButtonBackgroundColor,
                                color: design?.menuButtonFontColor
                            }}
                            onClick={() => +menuInfo.qty > 1 ? updateInfo("qty", +menuInfo.qty - 1) : null}
                        >-</span>
                        &nbsp;
                        <input
                            type="number"
                            className="searchat-qty-input"
                            value={menuInfo.qty}
                            onChange={(e) => +e.target.value >= 1 ? updateInfo("qty", +e.target.value) : null}
                            min={1}
                        />
                        &nbsp;
                        <span
                            className="plus"
                            style={{
                                background: design?.menuButtonBackgroundColor,
                                color: design?.menuButtonFontColor
                            }}
                            onClick={() => updateInfo("qty", +menuInfo.qty + 1)}
                        >+</span>
                    </div>
                </div>
            </div>
            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: 'center', marginBottom: "10px" }}>
                <div style={{ width: '100%', textAlign: "center", fontWeight: "500" }}>
                    {botConfig?.requestItemNoteMessage}
                </div>
            </div>
            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: 'center', marginBottom: "20px" }}>
                <div style={{ width: '100%', textAlign: "center", fontWeight: "500" }}>
                    <textarea
                        placeholder="Write your comment here"
                        rows={3}
                        style={{
                            width: "100%",
                            resize: "none",
                            padding: "5px",
                            borderRadius: "6px",
                            border: "2.2px solid #e3e6f0",
                        }}
                        onChange={(e) => updateInfo("note", e.target.value)}
                    />
                </div>
            </div>
            {
                Boolean(menuInfo?.item_options?.length) ?
                    <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: 'center', marginBottom: "20px" }}>
                        <div style={{ width: '100%', fontWeight: "500", overflow: "auto", height: "180px" }}>
                            {
                                menuInfo?.item_options?.map((option, option_index) =>
                                    <center style={{ width: "90%", margin: "auto" }}>
                                        <p style={{ width: '100%', fontWeight: "500", marginBottom: "10px" }}>
                                            {option?.option_description}
                                        </p>
                                        <center>
                                            {

                                                option.option.map((a, index) =>
                                                    <center className="radiobtn">
                                                        <input type="radio" id={a?.name}
                                                            checked={+menuInfo[`opt_price_${option_index}`] === +a.price}
                                                            onChange={() => {
                                                                const menuInfoToUpd = { ...menuInfo };
                                                                menuInfoToUpd[`opt_price_${option_index}`] = +a.price;
                                                                setMenuInfo({ ...menuInfoToUpd });
                                                            }}
                                                            name={a?.name} />
                                                        <label for={a?.name}>{a?.name} ({a?.price} {menuInfo?.currency})</label>
                                                    </center>
                                                )
                                            }
                                        </center>
                                    </center>
                                )
                            }
                        </div>
                    </div> : null
            }

            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: 'center' }}>
                <div style={{ width: '100%', textAlign: "center", fontWeight: "500" }}>
                    <span
                        className="searchat-chatbot-button"
                        style={{
                            width: "50%",
                            margin: "auto",
                            background: design?.menuButtonBackgroundColor,
                            color: design?.menuButtonFontColor
                        }}
                        onClick={() => {
                            eventDispatcher(
                                JSON.stringify({
                                    user_input: { ...menuInfo },
                                    bot_id: botInfo.bot_id
                                }),
                                (activities) => {
                                    console.log("activities",activities)
                                    activities.map(a => {
                                        setIncomingActivity(a);
                                    });
                                },
                                conversationID,
                                botInfo.bot_id
                            );
                            showTypingIndicator();
                            setMenuInfo(undefined);
                        }}
                    >
                        Add To Cart
                    </span>
                </div>
            </div>
        </div>
    )
}

export default ItemFormMenu;