import React, { useState } from "react";
import "../styles/mainMenu.css";


const ItemInfoMenu = ({
    menuInfo,
    setMenuInfo,
    design
}) => {


    const [imageToPreview, setImageToPreview] = useState(menuInfo?.item_icons?.length ? menuInfo?.item_icons[0] : design?.botIcon);

    return (
        <div
            className="searchat-chatbot-menu"
            style={{ height: "calc(100% - 165px)", background: design?.menuBackgroundColor, overflow: 'auto' }}
        >
            <div style={{ width: '100%' }}>
                <div style={{ width: '100%', textAlign: "right" }}>
                    <i
                        className="fas fa-window-close"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            setMenuInfo(undefined);
                        }}
                    ></i>
                </div>

                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <img src={imageToPreview} style={{ width: "auto", height: "200px", objectFit: "cover" }} />
                </div>

                <div style={{ padding: "10px", borderBottom: "1px solid #eee" }}>
                    {
                        menuInfo?.item_icons?.map(icon =>
                            <div
                                style={{
                                    display: "inline-block",
                                    width: "70px",
                                    cursor: 'pointer'
                                }}
                                onClick={() => setImageToPreview(icon)}
                            >
                                <img
                                    src={icon}
                                    style={{
                                        width: "60px",
                                        height: "60px",
                                        borderRadius: "60px",
                                        objectFit: "cover",
                                        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                        border: imageToPreview === icon ? `1px solid ${design?.headerColor}` : "none"
                                    }}
                                />
                            </div>
                        )
                    }
                </div>
                <div style={{ padding: "10px", height: '100%', borderBottom: "1px solid #eee" }}>
                    <b style={{ fontSize: '18px' }}>About this item:</b>
                    <br />
                    <br />
                    {menuInfo?.item_description}
                </div>
                <div style={{ padding: "10px", height: '100%' }}>
                    {
                        menuInfo?.item_features?.length ?
                            <div style={{ borderBottom: "1px solid #eee", marginBottom: "20px" }}>
                                <b style={{ fontSize: '18px' }}>Item Specifications:</b>
                                <br />
                                <br />
                                <table>
                                    <tr>
                                        <th style={{ width: "50%" }}>Feature</th>
                                        <th>Description</th>

                                    </tr>
                                    {
                                        menuInfo.item_features.map(a =>
                                            <tr>

                                                <td>{a.feature_name}</td>
                                                <td>{a.feature_value}</td>

                                            </tr>
                                        )
                                    }
                                </table>
                            </div> : null
                    }
                    {
                        menuInfo?.actions.map(action =>
                            action.type !== "link" ?
                                <div style={{ width: '100%', display: 'flex', alignItems: "center", justifyContent: "center" }}>
                                    <span
                                        className="searchat-chatbot-button"
                                        style={{
                                            fontSize: "14px",
                                            padding: "6px",
                                            width: "200px",
                                            float: 'right',
                                            background: design?.menuButtonBackgroundColor,
                                            color: design?.menuButtonFontColor
                                        }}
                                        onClick={() => {

                                            setMenuInfo({
                                                ...menuInfo,
                                                menu_type: "item_info",
                                            });
                                        }}
                                    >
                                        Add Cart
                                    </span>
                                </div> : null
                        )
                    }


                </div>

            </div>




        </div>
    );
}

export default ItemInfoMenu;