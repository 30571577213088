import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const Line = ({ 
  settings,     
  xData ,
  yData
 }) => {

  console.log("settings", settings);

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={{
        title: {
          text: settings.title,
        },
        plotOptions: {
          line: {
              dataLabels: {
                  enabled: true
              }
          }
      },
        xAxis: {
          categories: xData,
          title: {
            text: settings.xTitle,
          },
        },
        legend: {
          enabled: false,
        },
        yAxis: {
          min: Math.min(...yData),
          max: Math.max(...yData),
          title: {
            text: settings.yTitle,
            align: "middle",
          },
          labels: {
            overflow: "justify",
          },
        },
        series: [
          {
            type: "spline",
            data: yData,
          },
        ],
      }}
    />
  );
};

export default Line;
