import React, { useState, useEffect } from "react";
import {
    FacebookMessengerShareButton,
    FacebookMessengerIcon,
    EmailIcon,
    EmailShareButton,
    WhatsappIcon,
    WhatsappShareButton,
    TelegramShareButton,
    TelegramIcon,
  } from "react-share";
  import CryptoJS from 'crypto-js';

  const copyToClipboard = message => {
    navigator.clipboard.writeText(message);
   };

const ShareItemMenu = ({
    menuInfo,
    clientUrl,
    setMenuInfo,
    setNotification,
    design
}) => {

    const [urlToShare,setUrlToShare] = useState("");

    useEffect(() => {
        if(clientUrl){
            let encJson = CryptoJS.AES.encrypt(JSON.stringify(JSON.stringify({
                "item_id": menuInfo.item_id,
                "url": clientUrl?.split("?")[0]  
              })), "incubator").toString()
              let encData = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encJson))
              setUrlToShare(
                clientUrl?.includes("?") ?
                clientUrl?.concat(`&conversion=${encData}`,"&trigger=",menuInfo.itemToShare):
                clientUrl?.concat(`?conversion=${encData}`,"&trigger=",menuInfo.itemToShare) 
              );   
        }    
    },[]);

    return (
        <div
            className="searchat-chatbot-menu"
            style={{ height: "auto", background:design?.menuBackgroundColor }}
        >
               <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: 'flex-end',
                    fontSize:"18px",
                    color:"gray"
                }}
            >
                <i 
                    className="fas fa-window-close" 
                    style={{cursor:"pointer"}}
                    onClick = {() => setMenuInfo(undefined)}
                ></i>
            </div>
            <div style={{width:"100%",fontSize:'14px', marginBottom:"20px"}}>
                <div style={{width:"25%",display:"inline-block", textAlign:"center"}}>
                    <FacebookMessengerShareButton
                        url={encodeURI(urlToShare)}
                        title={menuInfo.itemToShare} 
                        appId={2944020909203376}   
                    >
                    <FacebookMessengerIcon size={32} round={true} style={{cursor:'pointer'}} className="share-icon" />
                    <br/>
                    Messenger
                    </FacebookMessengerShareButton>
                </div>
                <div style={{width:"25%",display:"inline-block", textAlign:"center"}} >
                    <EmailShareButton
                     url={urlToShare}
                        title={menuInfo.itemToShare}            
                    >
                    <EmailIcon size={32} round={true} style={{cursor:'pointer'}} className="share-icon"/>
                    <br/>
                    Email
                    </EmailShareButton>
                </div>
                <div style={{width:"25%",display:"inline-block", textAlign:"center"}}>
                    <WhatsappShareButton
                       url={encodeURI(urlToShare)}
                       title={menuInfo.itemToShare}             
                    >
                    <WhatsappIcon size={32} round={true} style={{cursor:'pointer'}} className="share-icon" />
                    <br/>
                    Whatsapp
                    </WhatsappShareButton>
                </div>
                <div style={{width:"25%",display:"inline-block", textAlign:"center"}}>
                    <TelegramShareButton
                    
                    value ={urlToShare}
                        title={menuInfo.itemToShare}           
                    >
                    <TelegramIcon size={32} round={true} style={{cursor:'pointer'}}  className="share-icon"/>
                    <br/>
                    Telegram
                    </TelegramShareButton>
                </div>
            </div>
            <hr/>
            <div style={{width:"100%",fontSize:'20px',marginTop:"20px"}}>
           
                <div style={{width:"240px",margin:"auto"}}>
                    <input
                        disabled={true}
                        value ={urlToShare}
                    /> &nbsp;
                    <i 
                        className="fas fa-copy"
                        style={{cursor:'pointer',color:"gray"}}
                        
                        onClick={() => {
                            
                            copyToClipboard(urlToShare);
                            setNotification("Copied!!");
                        }}
                    ></i>
                </div>
            </div>
            
        </div>
    );
}

export default ShareItemMenu;