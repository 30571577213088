import React from "react";
import { eventDispatcher } from "../../../Scripts";
import "../styles/mainMenu.css";
import "../styles/menuHover.scss";


const MainMenu = ({
    showTypingIndicator,
    mainMenuTriggers,
    setMenuInfo,
    design,
    botInfo
}) => {

    return (
        <div
            className="searchat-chatbot-menu"
            style={{ height: "auto", padding: '0px', background:design?.menuBackgroundColor }}
        >
              
            
            <div style={{ width: "100%", color: design?.menuButtonBackgroundColor }}>
                {
                    mainMenuTriggers?.mainMenuTriggers?.length ?
                    <div
                    className="searchat-main-menu-chatbot pulse"
                 
                    onClick={() => {
                        eventDispatcher(
                            JSON.stringify({
                                user_input:mainMenuTriggers.mainMenuTriggers[0].trigger,
                                bot_id: botInfo.bot_id
                            })
                        );
                        
                        showTypingIndicator();
                        setMenuInfo(undefined);
                    }}
                >
                    <div>
                    <i className="fas fa-bars" style={{ marginBottom: "10px" }}></i>
                    </div>
                    <div style={{ fontSize: "14px" }}>
                        Main Menu
                    </div>
                </div> : null
                }
                {
                    mainMenuTriggers?.productTriggers?.length ?
                        <div
                            className="searchat-main-menu-chatbot pulse"
                         
                            onClick={() => {
                                eventDispatcher(
                                    JSON.stringify({
                                        user_input:mainMenuTriggers.productTriggers[0].trigger,
                                        bot_id: botInfo.bot_id
                                    })
                                );
                        
                                showTypingIndicator();
                                setMenuInfo(undefined);
                            }}
                        >
                            <div>
                                <i className="fas fa-box" style={{ marginBottom: "10px" }}></i>
                            </div>
                            <div style={{ fontSize: "14px" }}>
                                Products
                            </div>
                        </div> : null
                }

                {
                    mainMenuTriggers?.addressTriggers?.length ?
                        <div
                            className="searchat-main-menu-chatbot pulse"
            
                            onClick={() => {
                                eventDispatcher(
                                    JSON.stringify({
                                        user_input:mainMenuTriggers.addressTriggers[0].trigger,
                                        bot_id: botInfo.bot_id
                                    })
                                );
                        
                                showTypingIndicator();
                                setMenuInfo(undefined);
                            }}
                        >
                            <div>
                                <i className="fas fa-address-card" style={{ marginBottom: "10px" }}></i>
                            </div>
                            <div style={{ fontSize: "14px" }}>
                                Address
                            </div>


                        </div> :
                        null}
                {
                    mainMenuTriggers?.weatherTriggers?.length ?
                        <div
                            className="searchat-main-menu-chatbot pulse"
                       
                            onClick={() => {
                                eventDispatcher(
                                    JSON.stringify({
                                        user_input:mainMenuTriggers.weatherTriggers[0].trigger,
                                        bot_id: botInfo.bot_id
                                    })
                                );
                          
                                showTypingIndicator();
                                setMenuInfo(undefined);
                            }}
                        >
                            <div>
                                <i className="fas fa-cloud-moon" style={{ marginBottom: "10px" }}></i>
                            </div>
                            <div style={{ fontSize: "14px" }}>
                                Weather
                            </div>


                        </div> : null
                }
                {
                    mainMenuTriggers?.privacyPolicyTriggers?.length ?
                    <div 
                        className="searchat-main-menu-chatbot pulse" 
              
                        onClick={() => {
                            eventDispatcher(
                                JSON.stringify({
                                    user_input:mainMenuTriggers.privacyPolicyTriggers[0].trigger,
                                    bot_id: botInfo.bot_id
                                })
                            );
             
                            showTypingIndicator();
                            setMenuInfo(undefined);
                        }}
                     >
                    <div>
                        <i className="fas fa-lock" style={{ marginBottom: "12px" }}></i>
                    </div>
                    <div style={{ fontSize: "14px" }}>
                        Privacy Policy
                    </div>
                </div>:null

                }

                {
                     mainMenuTriggers?.termsTriggers?.length ?
                     <div 
                        className="searchat-main-menu-chatbot pulse" 
                 
                        onClick={() => {
                            eventDispatcher(
                                JSON.stringify({
                                    user_input:mainMenuTriggers.termsTriggers[0].trigger,
                                    bot_id: botInfo.bot_id
                                })
                            );
                      
                            showTypingIndicator();
                            setMenuInfo(undefined);
                        }}
                    >
                     <div style={{ marginBottom: "10px" }}>
                         <i className="fas fa-file-contract" ></i>
                     </div>
                     <div style={{ fontSize: "14px" }}>
                         Terms & conditions
                     </div>
                 </div>:null
                }
            
            {
                     mainMenuTriggers?.tradingPolicyTriggers?.length ?
               
                <div 
                    className="searchat-main-menu-chatbot pulse" 
                   
                    onClick={() => {
                        eventDispatcher(
                            JSON.stringify({
                                user_input:mainMenuTriggers.tradingPolicyTriggers[0].trigger,
                                bot_id: botInfo.bot_id
                            })
                        );
             
                        showTypingIndicator();
                        setMenuInfo(undefined);
                    }}
                >
                    <div style={{ marginBottom: "10px" }}>
                        <i className="fas fa-balance-scale" ></i>
                    </div>
                    <div style={{ fontSize: "14px" }}>
                        Trading Policy
                    </div>


                </div>:null
                }

                <div 
                    className="searchat-main-menu-chatbot pulse"                   
                    onClick={() => {
                        setMenuInfo(undefined);
                        showTypingIndicator();
                        eventDispatcher(
                            JSON.stringify({
                                user_input: "FEEDBACK_REQUEST_PHONE_NUMBER",
                                bot_id: botInfo.bot_id
                            })
                        );
                    }}
                >
                    <div style={{ marginBottom: "10px" }}>
                        <i className="fas fa-star" ></i>
                    </div>
                    <div style={{ fontSize: "14px" }}>
                        Feedback
                    </div>


                </div>
                
            </div>
        </div>
    );
}

export default MainMenu;