import React from "react";
import "../styles/ItemSpecs.css";

const ItemSpecsCard = ({
    cardData,
    design,
}) => {
    return (
        <div
            className="standard-card"
            style={{borderRadius:design?.cardBorderRadius,background:design?.cardBackgroundColor}}
        >

            <table>
                <tr>
                    <th style={{ width: "50%" }}>Feature</th>
                    <th>Description</th>

                </tr>
                {
                    cardData.specs_data.map(a =>
                        <tr>

                            <td>{a.feature_name}</td>
                            <td>{a.feature_value}</td>
                           
                        </tr>
                    )
                }
            </table>
        </div>
    )

}


export default ItemSpecsCard;