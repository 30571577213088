import React, { useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const NotificationMessages = ({
    notification,
    setNotification
}) => {
 
    useEffect(() => {
        if(notification){
            toast.success(notification);
            setTimeout(() => setNotification(undefined),5000);
        }
    },[notification]);
    return(   
        <ToastContainer
        />
    )
}

export default NotificationMessages;