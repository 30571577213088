import React from "react";
import {
    ItemFormMenu,
    InvoiceMenu,
    CartInfoMenu,
    VerificationCodeMenu,
    MainMenu,
    RateUsMenu,
    SeeMorePicturesMenu,
    ShareItemMenu,
    ItemInfoMenu,
    LeadFormMenu,
    DataPrivacy,
    WhatsappMenu,
    
    ListMenu,
} from "./menus";
import "./styles/menu.css"

const Menu = ({
    menuInfo,
    setMenuInfo,
    showTypingIndicator,
    mainMenuTriggers,
    design,
    botInfo,
    voiceLanguage,
    botConfig,
    windowWidth,
    clientUrl,
    setNotification,
    setLoadingScreen,
    conversationID ,
    setIncomingActivity,
    setIsTtsActive,
    voicePlugin
}) => {
    console.log("menuInfo",menuInfo)
    return (
        <>  
            {
                 menuInfo?.menu_type === "item_info" ? 
                 <ItemFormMenu
                    menuInfo = {menuInfo}
                    setMenuInfo = {setMenuInfo}
                    showTypingIndicator = {showTypingIndicator}
                    design = {design}
                    botConfig = {botConfig}
                    conversationID = {conversationID}
                    setIncomingActivity = {setIncomingActivity}
                    botInfo = {botInfo}
                 />
                 :null
            }   
             {
                menuInfo?.menu_type === "data_privacy" ? 
                <DataPrivacy
                   setMenuInfo = {setMenuInfo}
                   menuInfo = {menuInfo}      
                   design = {design}
                   setLoadingScreen  ={setLoadingScreen}
               />:null
            }
             {
                 menuInfo?.menu_type === "invoice" ? 
                 <InvoiceMenu
                    menuInfo = {menuInfo}
                    setMenuInfo = {setMenuInfo}
                    showTypingIndicator = {showTypingIndicator}
                    design = {design}
                    botConfig = {botConfig}
                    botInfo = {botInfo}
                    conversationID = {conversationID}
                    setIncomingActivity = {setIncomingActivity}
                 />
                 :null
            }    

            {
                 menuInfo?.menu_type === "cart_info" ? 
                 <CartInfoMenu
                    menuInfo = {menuInfo}
                    setMenuInfo = {setMenuInfo}
                    showTypingIndicator = {showTypingIndicator}
                    design = {design}
                    botConfig = {botConfig}
                    botInfo = {botInfo}
                    conversationID = {conversationID}
                    setIncomingActivity = {setIncomingActivity}
                    voicePlugin = {voicePlugin}
                    setIsTtsActive = {setIsTtsActive}
                 />
                 :null
            }   

            {   
                 menuInfo?.menu_type === "verification_code" ? 
                 <VerificationCodeMenu
                    menuInfo = {menuInfo}
                    setMenuInfo = {setMenuInfo}
                    showTypingIndicator = {showTypingIndicator}
                    design = {design}
                    botConfig = {botConfig}
                    botInfo = {botInfo}
                    conversationID = {conversationID}
                    setIncomingActivity = {setIncomingActivity}
                    voicePlugin = {voicePlugin}
                    setIsTtsActive = {setIsTtsActive}
                 />
                 :null
            }  
            {   
                 menuInfo?.menu_type === "main_menu" ? 
       
                <MainMenu
                    showTypingIndicator = {showTypingIndicator}
                    mainMenuTriggers = {mainMenuTriggers}
                    setMenuInfo = {setMenuInfo}
                    design = {design}
                    botConfig = {botConfig}
                    botInfo = {botInfo}
                    conversationID = {conversationID}
                    setIncomingActivity = {setIncomingActivity}
                />:null

            }

            {
                 menuInfo?.menu_type === "rating_menu" ? 
                 <RateUsMenu
                     setMenuInfo = {setMenuInfo}
                     menuInfo = {menuInfo}
                     botInfo = {botInfo}
                     showTypingIndicator = {showTypingIndicator}
                     voiceLanguage = {voiceLanguage}
                     design = {design}
                     botConfig = {botConfig}
                     conversationID = {conversationID}
                     setIncomingActivity = {setIncomingActivity}
                 />:null
            }
           
           {
                 menuInfo?.menu_type === "see_more_pictures" ? 
                 <SeeMorePicturesMenu
                     setMenuInfo = {setMenuInfo}
                     menuInfo = {menuInfo}         
                     design = {design}   
                     windowWidth = {windowWidth}
                     
                 />:null
            }

            {
                 menuInfo?.menu_type === "share_item" ? 
                 <ShareItemMenu
                     setMenuInfo = {setMenuInfo}
                     menuInfo = {menuInfo}    
                     clientUrl = {clientUrl}    
                     setNotification  ={setNotification}    
                     design = {design}
                 />:null
            }

            {
                 menuInfo?.menu_type === "item_page" ? 
                 <ItemInfoMenu
                     setMenuInfo = {setMenuInfo}
                     menuInfo = {menuInfo}      
                     design = {design}
                     
                 />:null
            }

            {
                menuInfo?.menu_type === "lead_form" ? 
                <LeadFormMenu
                   setMenuInfo = {setMenuInfo}
                   menuInfo = {menuInfo} 
                   botInfo = {botInfo}     
                   design = {design}
                   setLoadingScreen  ={setLoadingScreen}
               />:null
            }

            {
                menuInfo?.menu_type === "list_menu" ? 
                <ListMenu
                   setMenuInfo = {setMenuInfo}
                   menuInfo = {menuInfo}      
                   design = {design}
                   setLoadingScreen  ={setLoadingScreen}
                   conversationID = {conversationID}
                   setIncomingActivity = {setIncomingActivity}
                   botInfo = {botInfo}
                   voicePlugin = {voicePlugin}
                   setIsTtsActive = {setIsTtsActive}
               />:null
            }
          
        </>
    );
}

export default Menu;