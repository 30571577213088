import React from "react";
import "../styles/standardCard.css";
import Button from "../../Button";
import { eventDispatcher, generatetRandomId } from "../../../Scripts";


const ar_regex = new RegExp("[\u0600-\u06ff]|[\u0750-\u077f]|[\ufb50-\ufc3f]|[\ufe70-\ufefc]");
const StandardCard = ({
    cardData,
    showTypingIndicator,
    isFixedImageSize,
    design,
    botInfo,
    setMenuInfo,
    setIncomingActivity ,
    conversationID ,
    setIsTtsActive ,
    voicePlugin,
    pushToActivitiesHistory
}) => {


    return (
        <div
            className="standard-card"
            style={{
                borderRadius:design?.cardBorderRadius
            }}
            
        >
          
            <div className="standard-card-img-cover">
            {
                cardData?.isMarketing ?
                <span
                    style={{
                        position:"absolute",
                        top:"10px",
                        left:"10px",
                        background:design?.cardThemeColor,
                        opacity:0.8,
                        padding:"5px",
                        borderRadius:"10px",
                        color: design?.cardButtonFontColor
                    }}
                >
                    Promotion
                </span>
                :null
            }

{
    cardData?.isOfferCard ?
    <span
              className="share-product-icon"
              id="share-item"
              onClick={() => {
                setMenuInfo({
                  menu_type: "share_item",
                  itemToShare: cardData.heading
                });
              }}
            >
              <i className="fa fa-share-alt"></i>
            </span>:null
}
          {
                    Boolean(cardData?.image_url) ?
                        <img
                            src={cardData?.image_url}
                            style={{ height: isFixedImageSize ? "200px" : "100%" }}
                        /> :
                        null
                }

                {
                    Boolean(cardData?.video_url) ?
                        <div style={{ width: "100%" }}>
                            <video
                                controls
                                autoPlay
                                muted
                                style={{ width: "100%" }}
                            >
                                <source 
                                src={cardData?.video_url}
                                ></source>
                            </video>
                          
                            {/* <iframe
                                src={cardData?.video_url}
                                style={{ width: "100%", margin: "auto", height: "250px" }}
                                allowFullScreen
                      
                                
                            /> */}


                        </div> : null
                }



            </div>
         

            {
                Boolean(cardData?.heading) ||
                    Boolean(cardData?.subheading) ||
                    cardData?.actions.length ?
                    <div
                        className="standard-card-desc"
                        style ={{ background:design?.cardBackgroundColor}}
                    >
                        {
                            Boolean(cardData?.heading) ?
                                <div
                                    className="standard-card-heading"
                                    style={{
                                        marginBottom: "10px",
                                        textAlign: ar_regex.test(cardData?.heading) ? "right" : "left",
                                        direction:ar_regex.test(cardData?.heading) ? "rtl" : "ltr",
                                        color:  design?.cardFontColor
                                    }}
                                    dangerouslySetInnerHTML ={{__html: cardData?.heading}}
                                >
                                
                                </div>
                                : null
                        }

                        {
                            Boolean(cardData?.subheading) ?
                                <p
                                    style={{
                                        marginBottom: "10px",
                                        textAlign: ar_regex.test(cardData?.subheading) ? "right" : "left",
                                        direction:ar_regex.test(cardData?.subheading) ? "rtl" : "ltr",
                                        color:  design?.cardFontColor
                                    }}
                                    dangerouslySetInnerHTML ={{__html: cardData?.subheading}}
                                >
                                   
                                </p>
                                : null
                        }
                     
                        {
                            cardData?.actions.map(
                                action =>
                                    <Button
                                        key={generatetRandomId()}
                                        textColor={design?.cardButtonFontColor}
                                        backgroundColor={design?.cardThemeColor}
                                        label={action.label}
                                        customStyles={{ marginBottom: "5px" }}
                                        onclick={() => {
                                            console.log("conversationID",conversationID)
                                            if (action.type === "postback") {
                                                pushToActivitiesHistory({
                                                    type: "message",
                                                    text: action.label,
                                                    from: "user",
                                                });
                                                if (typeof action.postback === "object") {
                                                   
                                                    eventDispatcher(
                                                        JSON.stringify({
                                                            user_input: action.postback,
                                                            bot_id: botInfo.bot_id
                                                        }),
                                                        (activities) => {
                                                            activities.map(a => {
                                                                setIncomingActivity(a);
                                                            });
                                                            var text = "";
                                                            const text_arr = activities.filter(a => a.type === "message");
                                                            text_arr.forEach(a => {
                                                                text = text.concat(` ${a.text}`);
                                                            });
                                                            const event = new Event("dispatchTextToSpeech");
                                                            event.data = text.replace(new RegExp('<[^>]*>', 'g'), '');
                                                            event.showMuteIcon = () => setIsTtsActive(true);
                                                            event.hideMuteIcon = () => setIsTtsActive(false);
                                                            event.tts_gender = voicePlugin?.tts_gender;
                                                            window.dispatchEvent(event);
                                                        },
                                                        conversationID,
                                                        botInfo.bot_id
                                                    );
                                                   
                                                }
                                                else {
                                                    eventDispatcher(
                                                        JSON.stringify({
                                                            user_input: action.postback,
                                                            bot_id: botInfo.bot_id
                                                        }),
                                                        (activities) => {
                                                            activities.map(a => {
                                                                setIncomingActivity(a);
                                                            });
                                                            var text = "";
                                                            const text_arr = activities.filter(a => a.type === "message");
                                                            text_arr.forEach(a => {
                                                                text = text.concat(` ${a.text}`);
                                                            });
                                                            const event = new Event("dispatchTextToSpeech");
                                                            event.data = text.replace(new RegExp('<[^>]*>', 'g'), '');
                                                            event.showMuteIcon = () => setIsTtsActive(true);
                                                            event.hideMuteIcon = () => setIsTtsActive(false);
                                                            event.tts_gender = voicePlugin?.tts_gender;
                                                            window.dispatchEvent(event);
                                                        },
                                                        conversationID,
                                                        botInfo.bot_id
                                                    );
                                                   
                                                }
                                                showTypingIndicator();
                                            }
                                            else {
                                                window.open(action.url, "_blank");
                                            }
                                        }}
                                    />
                            )
                        }

                    </div> : null
            }
           
   </div>
    );

}


export default StandardCard;

