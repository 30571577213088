import React from "react";
import { generatetRandomId } from "../../../Scripts";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';



SwiperCore.use([
    Navigation,
    Pagination,
    Scrollbar,
    A11y,
    EffectFade
]);

const SeeMorePicturesMenu = ({
    menuInfo,
    windowWidth,
    setMenuInfo,
    design
}) => {

    const getNumberOfSlidesPerView = () => {
        return parseInt(windowWidth / 400) > menuInfo.images.length ? menuInfo.images.length : parseInt(windowWidth / 400);
    }



    return (
        <div
            className="searchat-chatbot-menu"
            style={{ height: "280px", background: design?.menuBackgroundColor }}
        >
            <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: 'flex-end',
                    fontSize:"18px",
                    color:"gray"
                }}
            >
                <i 
                    className="fas fa-window-close" 
                    style={{cursor:"pointer"}}
                    onClick = {() => setMenuInfo(undefined)}
                ></i>
            </div>

            <div className="searchat-carousel-div">

                <Swiper
                    spaceBetween={1}
                    slidesPerView={getNumberOfSlidesPerView() > 0 ? (getNumberOfSlidesPerView() + 0.1) : 1.1}

                    // pagination={{
                    //     "clickable": true
                    //   }}
                    navigation={menuInfo.images.length > getNumberOfSlidesPerView()}

                    // pagination={{ clickable: true }}
                    // scrollbar={{ draggable: true }}
                    onSwiper={(swiper) => console.log(swiper)}
                    onSlideChange={() => console.log('slide change')}
                >
                    {
                        menuInfo.images.map((image_url, index) =>
                            <SwiperSlide
                                key={generatetRandomId()}
                            >
                                <div
                                    className="standard-card"
                                >
                                    <div className="standard-card-img-cover">
                                        {
                                            Boolean(image_url) ?
                                                <img
                                                    src={image_url}
                                                    style={{ height:  "200px"  }}
                                                /> :
                                                null
                                        }


                                    </div>
                                </div>

                            </SwiperSlide>
                        )
                    }
                </Swiper>

            </div>



        </div>
    )
}

export default SeeMorePicturesMenu;