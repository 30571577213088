import { createPostJSON } from "../helper/customs";
const mockURL = "https://mock-web-2.azurewebsites.net";
const sendMessage = (data) => {
  return fetch(`${mockURL}/api/messages`, createPostJSON(data))
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export { sendMessage };
