import React from 'react'
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import { eventDispatcher, generatetRandomId } from "../../../Scripts";

const FullCalendarCard = ({
    cardData,
    showTypingIndicator,
    isFixedImageSize,
    design,
    botInfo,
    setMenuInfo,
    setIncomingActivity,
    conversationID,
    setIsTtsActive ,
    voicePlugin
}) =>{

    const handleDateClick = (arg) => { // bind with an arrow function
        eventDispatcher(
            JSON.stringify({
                user_input: arg.dateStr,
                bot_id: botInfo.bot_id
            }),
            (activities) => {
                activities.map(a => {
                    console.log("a", a)
                    setIncomingActivity(a);
                });
                var text = "";
                const text_arr = activities.filter(a => a.type === "message");
                text_arr.forEach(a => {
                    text = text.concat(` ${a.text}`);
                });
                const event = new Event("dispatchTextToSpeech");
                event.data = text.replace(new RegExp('<[^>]*>', 'g'), '');
                event.showMuteIcon = () => setIsTtsActive(true);
                event.hideMuteIcon = () => setIsTtsActive(false);
                event.tts_gender = voicePlugin?.tts_gender;
                window.dispatchEvent(event);
            },
            conversationID,
            botInfo.bot_id
        );

        showTypingIndicator();
     
    }
    

    return (
        <div
     
        style={{
            borderRadius: design?.cardBorderRadius,
            width: "100%",
            background:"white",
            padding:"20px",
            boxShadow: "5px 5px 15px rgba(0,0,0, .3)",
            marginBottom:"20px"
        }}

    >
      <FullCalendar
        plugins={[ dayGridPlugin, interactionPlugin ]}
        dateClick={handleDateClick}
        weekends={true}
        events={[
            { title: 'موعد', date: '2022-04-25' },
            { title: 'موعد', date: '2022-04-27' },
            { title: 'موعد', date: '2022-04-28' }
          ]}
          
      />
      </div>
    )
  

}

export default FullCalendarCard;