
import  eventDispatcher  from "./eventDispatcher";


export default function sttDispatcher(dispatchToMoc,botInfo,lang,cb, itemToAskAbout) {
    let result_txt = '';
    window.Speech_T_text(botInfo.file_name,lang)
    .then((result) => {    
        result_txt = result;
    })
    .then(() => {
        cb(result_txt);   
    });
}