
import {
  createGetJSON,
} from "../helper/customs";

const getIpAddress = () => {
    return fetch(
        "https://api.ipify.org/?format=json",
      createGetJSON()
    )
      .then((response) => {
        return response.json();
      })
      .catch((err) => console.log(err));
};




export { 
    getIpAddress
};