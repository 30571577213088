import React, { useEffect } from "react";
import { eventDispatcher } from "../../../Scripts";
import country_codes from "../phone_counrty_code.json";


const CartInfoMenu = ({
    menuInfo,
    setMenuInfo,
    showTypingIndicator,
    design,
    botInfo,
    conversationID,
    setIncomingActivity,
    voicePlugin ,
    setIsTtsActive
}) => {


    useEffect(() => { 
            setMenuInfo({
                ...menuInfo,
                address: menuInfo?.userLocationData?.region_name || "Amman",
                country_code:  menuInfo?.userLocationData?.location?.calling_code || 
                "962" 
            });   
    },[]);

    const updateInfo = (entity, value) => {
        const menuInfoToUpd = { ...menuInfo };
        menuInfoToUpd[entity] = value;
        setMenuInfo(menuInfoToUpd);
    }
    
    const validate = () => {
        const missing = [];
        Object.keys(menuInfo).map(a => {
            if (typeof menuInfo[a] === 'string') {
                if (
                    !menuInfo[a].length
                ) {
                    missing.push(a);
                }
            }
        });
        return !missing.length && !isNaN(menuInfo.phone);
    };

    return (
        <div
            className="searchat-chatbot-menu"
            style={{ height: "auto" ,background:design?.menuBackgroundColor,
            color: design?.menuFontColor}}
        >
            <div style={{ width: "100%", marginBottom: "20px" }}>
                <div style={{ display: "inline-block", width: "50%", textAlign: "center", padding: "5px" }}>
                    <input
                        placeholder="Your Name"
                        value={menuInfo.name}
                        onChange={(e) => updateInfo("name", e.target.value)}
                        style={{ border: "2px solid rgb(221, 221, 221)", padding: "5px", borderRadius: "5px", width: "100%" }}
                    />
                </div>
                <div style={{ display: "inline-block", width: "50%", textAlign: "center", padding: "5px" }}>
                    <input
                        value={Boolean(menuInfo.address) ? menuInfo.address: menuInfo.userLocationData?.region_name}
                        onChange={(e) => updateInfo("address", e.target.value)}
                        placeholder="Your Address"
                        style={{ border: "2px solid rgb(221, 221, 221)", padding: "5px", borderRadius: "5px", width: "100%" }}
                    />
                </div>
            </div>
            <div style={{ width: "100%", marginBottom: "30px" }}>
                <div style={{ display: "inline-block", width: "50%", textAlign: "center", padding: "5px" }}>
                    <select
                        placeholder="Country Code"
                        value={
                            Boolean(menuInfo.country_code) ?   menuInfo.country_code:
                            menuInfo.userLocationData?.location?.calling_code || 
                            "962"        
                        }
                        onChange={(e) => updateInfo("country_code", e.target.value)}
                        style={{ border: "2px solid rgb(221, 221, 221)", padding: "4px", borderRadius: "5px", width: "100%" }}
                    >
                        {
                            country_codes.map(a =>
                                <option value={`${a.dial_code ? a.dial_code.replace("+", "") : null
                                    }`}>{`${a.name} (${a.dial_code})`}</option>
                            )
                        }
                    </select>
                </div>
                <div style={{ display: "inline-block", width: "50%", textAlign: "center", paddingRight: "5px" }}>
                    <input
                        placeholder="Your Phone"
                        value={menuInfo.phone}
                        onChange={(e) => updateInfo("phone", e.target.value)}
                        style={{ border: "2px solid rgb(221, 221, 221)", padding: "5px", borderRadius: "5px", width: "100%" }}
                    />
                </div>
            </div>
            <div style={{ width: "100%" }}>
                <div style={{ width: "50%", display: 'inline-block', textAlign: "center", padding: "10px" }}>
                    <a
                        className="searchat-chatbot-button"
                      
                        style={{ fontSize: "14px", padding: "6px",   background:design?.menuButtonBackgroundColor,
                        color: design?.menuButtonFontColor }}
                        onClick={() => {

                            setMenuInfo(undefined);

                        }}
                    >
                        Cancel
                    </a>
                </div>
                <div style={{ width: "50%", textAlign: "center", display: 'inline-block', paddingLeft: "5px", margin: "auto" }}>
               
                    <a
                        className="searchat-chatbot-button"
                        disable={!validate()}
                       
                        style={{ background: validate() ? design?.menuButtonBackgroundColor : "gray", fontSize: "14px", padding: "6px",color: design?.menuButtonFontColor }}
                        onClick={() => {
                            eventDispatcher(
                                JSON.stringify({
                                    user_input:{
                                        ...menuInfo, menu_type: undefined, text: "SEND_PHONE_VERIFICATION_CODE_REQUEST" 
                                    },
                                    bot_id: botInfo.bot_id
                                }),
                                (activities) => {
                                    activities.map(a => {
                                        setIncomingActivity(a);
                                    });                               
                                },
                                conversationID,
                                botInfo.bot_id
                            );
                        
                            setMenuInfo(undefined);
                            showTypingIndicator();
                        }}
                       
                    >
                        Submit
                    </a>
                </div>
            </div>
        </div>
    );
}

export default CartInfoMenu;