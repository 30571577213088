import React from "react";
import { 
    BotMesssage,
    UserMessage
} from "./messages";

const Message = ({
    message, 
    from,
    design,
    isAppearance 
}) => {
    return (
        <div 
            style = {{
                width:"100%",
                marginBottom:"20px",
                display:"flex",
                justifyContent: from === "bot" ?"flex-start":"flex-end",
                alignItems:"center"
                
            }}
        >
            {
                from === "bot" && Boolean(message)? 
                <BotMesssage
                    message = {message}
                    design = {design}
                    isAppearance = {isAppearance}
                />:null
            }
             {
                from === "user" ? 
                <UserMessage
                    message = {message}
                    design = {design}
                    isAppearance = {isAppearance}
                />:null
            }
        </div>
    );
}


export default Message;