import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Routes from "./routes";
import Home from "./Pages/Home/index";

// console.log = () => {}

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path={Routes.home} component={Home}></Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
