import React from "react";
import "./style.css";

const DynamicTable = ({
  mapper,
  data,
  design
}) => {
  console.log("mapper", mapper)
  return (
    <div style={{ width: "100%", marginBottom: "10px" }}>
      <div style={{ overflowX: "auto" }}>
        <table id="table">
          <thead>
            {
              Object.keys(mapper).length ?
                <tr>
                  {Object.keys(mapper).map(
                    (column2, index) =>
                      <th
                        style={{ background: design?.headerColor }}
                        scope="col">{column2}</th>
                  )
                  }
                </tr> :
                <tr>
                  {Object.keys(data[0]).map(
                    (column2, index) =>
                      <th
                        style={{ background: design?.headerColor }}
                        scope="col">{column2}</th>
                  )
                  }
                </tr>

            }
          </thead>
          <tbody>
            {
              data.map(row =>
                <tr>
                  {
                    Object.keys(row).map(
                      key => <td> {`${row[key]}`}</td>
                    )
                  }

                </tr>
              )
            }

          </tbody>
        </table>
      </div>
    </div>
  )
}
export default DynamicTable;


