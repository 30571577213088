import React, { useState } from "react";
import ReactStars from "react-rating-stars-component";
import { sttDispatcher, eventDispatcher } from "../../../Scripts";

const RateUsMenu = ({
    menuInfo,
    setMenuInfo,
    showTypingIndicator,
    botInfo,
    voiceLanguage,
    design
}) => {
    const [isVoiceActive, setIsVoiceActive] = useState(false);
    return (
        <div
            className="searchat-chatbot-menu"
            style={{ height: "280px", background:design?.menuBackgroundColor }}
        >
             <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: 'flex-end',
                    fontSize:"18px",
                    color:"gray"
                }}
            >
                <i 
                    className="fas fa-window-close" 
                    style={{cursor:"pointer"}}
                    onClick = {() => setMenuInfo(undefined)}
                ></i>
            </div>
            <div style={{ width: "100%", marginBottom: "40px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <ReactStars
                    count={5}
                    onChange={(e) => setMenuInfo({
                        ...menuInfo,
                        rating: e
                    })}
                    size={32}
                    activeColor="#ffd700"
                    value={menuInfo.rating}

                />
            </div>
            <div style={{ width: "100%", marginBottom: "30px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <textarea
                    placeholder="Your Notes"
                    value={menuInfo.notes}
                    onChange={(e) => setMenuInfo({
                        ...menuInfo,
                        rating: e.target.value
                    })}
                    style={{ border: "2px solid rgb(221, 221, 221)", padding: "5px", borderRadius: "5px", width: "100%", maxWidth: "400px" }}
                />  &nbsp;
            </div>
            <div style={{ width: "100%", marginBottom: "30px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div
                    className="searchat-btn-div"
                    style={{ display: 'inline-block' }}
                    onClick = {() => {
                        setIsVoiceActive(true);
                                setTimeout(() => setIsVoiceActive(false), 5000);
                                sttDispatcher(false, botInfo, voiceLanguage, (text) => {
                                    setMenuInfo({
                                        ...menuInfo,
                                        notes: text
                                    });              
                                    showTypingIndicator();
                                });
                    }}
                >
                    {
                        isVoiceActive ?
                            <div className="sonar-emitter">
                                <i className="fas fa-microphone" id="searchat-bot-voice-btn"></i>
                                <div className="sonar-wave sonar-wave1">
                                    <div className="sonar-wave sonar-wave2"></div>
                                    <div className="sonar-wave sonar-wave3"></div>
                                    <div className="sonar-wave sonar-wave4"></div>
                                </div>
                            </div> : <i className="fas fa-microphone" id="searchat-bot-voice-btn"></i>
                    }

                </div>
            </div>

            <div style={{ width: "100%" }}>
                <div style={{ width: "100%", textAlign: "center", display: 'inline-block', paddingLeft: "5px", margin: "auto" }}>
                    <span
                        className="searchat-chatbot-button"
                        style={{   background:design?.menuButtonBackgroundColor,
                            color: design?.menuButtonFontColor, fontSize: "14px", padding: "6px", width: "100%", maxWidth: "200px", margin: "auto" }}
                        onClick = {() => {
                            showTypingIndicator();
                            setMenuInfo(undefined);
                            eventDispatcher(
                            JSON.stringify({
                                bot_id: botInfo.bot_id,
                                user_input: {
                                    text: "FEEDBACK_SUBMIT_CONFIRM",
                                    ...menuInfo
                                }
                            })
                        
                        )}}
                    >
                        Submit
                    </span>
                </div>
            </div>
        </div>
    );
}

export default RateUsMenu;