import React, {useState} from "react";


const increase_brightness = (hex, percent) => {  
    hex = hex.replace(/^\s*#|\s*$/g, '');
    if(hex.length === 3){
        hex = hex.replace(/(.)/g, '$1$1');
    }
    var r = parseInt(hex.substr(0, 2), 16),
        g = parseInt(hex.substr(2, 2), 16),
        b = parseInt(hex.substr(4, 2), 16);
    return `rgb(${r-r*0.1},${g-g*0.1},${b-b*0.1})`;
}


export default function Button({
    key,
    onclick,
    label,
    textColor,
    backgroundColor,
    customStyles
}) {

    const [style, setStyle ] = useState({ 
        ...customStyles, 
        color: textColor, 
        background: backgroundColor 
    });
 
    return (
        <>
        {
            key ? 
            <span
                className="searchat-chatbot-button"
                key={key}
                style={{ ...style }}
                onClick={() => onclick()}
                onMouseOver = {() => 
                    setStyle({
                        ...style, 
                        background: increase_brightness(backgroundColor )
                    })
                }
                onMouseLeave = {() => 
                    setStyle({
                        ...style, 
                        background: backgroundColor
                    })
                }
            >
                {label}
            </span>: 
            <span
                className="searchat-chatbot-button"
                style={{ ...style }}
                onClick={() => onclick()}
                onMouseOver = {() => 
                    setStyle({
                        ...style, 
                        background: increase_brightness(backgroundColor )
                    })
                }
                onMouseLeave = {() => 
                    setStyle({
                        ...style, 
                        background: backgroundColor
                    })
                }
            >
                {label}
            </span>
        }
       
        </>
    )

}