import React, {useState} from 'react';
import { eventDispatcher } from '../../../Scripts';
import Modal from 'react-modal';
import Button from '../../Button';

const approximate = (value) => Math.floor(value * 100) / 100;

const InvoiceMenu = ({
    menuInfo,
    setMenuInfo,
    showTypingIndicator,
    design,
    botInfo,
    conversationID ,
    setIncomingActivity 
}) => {
    const [itemToEditNotes, setItemToEditNotes ] = useState();

    const calculateTotalSale = (cart) => {
        var menuInfoToUpd = { ...menuInfo };
        menuInfoToUpd.total_price = approximate(cart.reduce((a, b) => a + (b.sale * b.qty || 0), 0));
        setMenuInfo(menuInfoToUpd);
    };


    const updateCart = (entity, value, index) => {
        const menuInfoToUpd = { ...menuInfo };
        menuInfoToUpd.cart[index][entity] = value;
        setMenuInfo(menuInfoToUpd);
    }

    const deleteItem = (index) => {
        var menuInfoToUpd = { ...menuInfo };
        menuInfoToUpd.cart = menuInfoToUpd.cart.filter((a, item_index) => item_index !== index);
        menuInfoToUpd.total_price = approximate(menuInfoToUpd.cart.reduce((a, b) => a + (b.sale * b.qty || 0), 0));
        setMenuInfo({...menuInfoToUpd});
        
    }


    return (
        <div
            className="searchat-chatbot-menu"
            style={{ height: "calc(100% - 165px)" ,background:design?.menuBackgroundColor,
            color: design?.menuFontColor}}
        >

            <Modal
                isOpen={itemToEditNotes}
                onRequestClose={() => setItemToEditNotes(undefined)}  
                style={{height:"200px"}}  
            >
                    <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: 'flex-end',
                    fontSize:"18px",
                    color:"gray"
                }}
            >
                <i 
                    className="fas fa-window-close" 
                    style={{cursor:"pointer"}}
                    onClick = {() => setItemToEditNotes(undefined)}
                ></i>
            </div>
                <div style={{width:"100%",textAlign:"center",marginBottom:"20px"}}>
                    Notes
                </div>  
                <div style={{width:"100%",textAlign:"center",marginBottom:"20px"}}>
                    <textarea
                        style={{ width:'100%', maxWidth:"400px"}}
                        value = {itemToEditNotes?.note}
                        onChange = {(e) => {
                            const itemToEditNotesToUpd = {...itemToEditNotes};
                            itemToEditNotesToUpd.note = e.target.value;
                           setItemToEditNotes({...itemToEditNotesToUpd});
                        }}
                    />
                </div>  
                <div style={{width:"100%"}}>
                <span
                        className="searchat-chatbot-button"
                        style={{ fontSize: "14px", padding: "6px", maxWidth: "200px", margin: 'auto',   background:design?.menuButtonBackgroundColor,
                        color: design?.menuButtonFontColor }}
                        onClick={() => {
                           const menuInfoToUpd = {...menuInfo};
                           menuInfoToUpd.cart[itemToEditNotes.index].note = itemToEditNotes.note;
                           setMenuInfo({...menuInfoToUpd});
                           setItemToEditNotes(undefined);
                        }}
                    >
                       Save
                    </span>
                </div>  
            </Modal>
            <div style={{ width: "100%", padding: "10px", marginBottom: "5px" }}>
                <div style={{ width: "50%", display: 'inline-block', fontWeight: "500" }}>
                    {
                        menuInfo?.cart_info?.phone_verified &&
                        menuInfo.cart_info.name ?
                        `${menuInfo.cart_info.name }'s cart`
                        :"Cart"
                    }
                    
                </div>
              
                <div style={{ width: "50%", display: 'inline-block', textAlign: "right" }}>
                    <span
                        className="searchat-chatbot-button"
                        style={{ fontSize: "14px", padding: "6px", maxWidth: "200px", float: 'right' , background:design?.menuButtonBackgroundColor,
                        color: design?.menuButtonFontColor }}
                        onClick={() => {
                            eventDispatcher(
                                JSON.stringify({
                                    user_input: {
                                        modified_user_info: {
                                            ...menuInfo,
                                        },
                                        text: "CONTINUE_CART"
                                    },
                                  
                                    bot_id: botInfo.bot_id
                                }),

                                (activities) => {
                                    activities.map(a => {
                                        setIncomingActivity(a);
                                    });                               
                                },
                                conversationID,
                                botInfo.bot_id
                            );
                            
                            setMenuInfo(undefined);
                            showTypingIndicator();
                        }}
                    >
                        Continue Shopping&nbsp; <i className="fa fa-arrow-right" aria-hidden="true"></i>
                    </span>
                </div>

            </div>
                    {
                        menuInfo?.cart_info?.phone_verified &&
                        menuInfo.cart_info.phone ?
                        <div style={{ width: "100%", borderBottom: "1px solid #eee", padding: "10px", marginBottom: "20px" }}>
                            <div style={{ width: "50%", display: 'inline-block', fontWeight: "500" }}>
                               Phone Number: {menuInfo.cart_info.phone}         
                            </div> 
                        </div>              
                        :null
                    }
          
            <div
                style={{ width: "100%", padding: "10px", borderBottom: "1px solid #eee", marginBottom: "20px" }}
            >


                <div style={{ width: "32%", display: 'inline-block' }}>
                    Item
                </div>
                <div style={{ width: "27%", display: 'inline-block', textAlign: "center" }}>
                    QTY
                </div>

                <div style={{ width: "22%", display: 'inline-block', textAlign: "center" }}>
                    Price
                </div>
                {
                                    menuInfo.editable ?
                                    <div style={{ width: "10%", display: 'inline-block', textAlign: "center" }}>
                                    Notes
                                </div>
                                    :null
               
                }




            </div>

            <div
                style={{ height:menuInfo?.cart_info?.phone_verified && menuInfo.cart_info.name ?"calc(100% - 440px)" : "calc(100% - 340px)", marginBottom: "10px", borderBottom: "1px solid #eee", overflowY: "auto", overflowX:"hidden" }}
            >
                {
                    menuInfo.cart.map((a, index) =>

                        <div
                            style={{ width: "100%", padding: "10px", border: "1px solid #eee", position: "relative" }}
                        >
                            
                            <div style={{ width: "30%", display: 'inline-block', overflow: "hidden", fontSize: "14px" }}>
                                {a.title}
                            </div>
                            <div style={{ width: "30%", display: 'inline-block', textAlign: "center" }}>
                                {
                                    menuInfo.editable ?
                                        <div className="number">
                                            <span
                                                className="minus"
                                                style={{ background: design?.headerColor, width: "18px", height: "18px", borderRadius: "18px", padding: '0px', fontSize: "12px",fontWeight: "600" }}
                                                onClick={(e) => {
                                                    if (+menuInfo.cart[index].qty > 1) {
                                                        updateCart("qty", +menuInfo.cart[index].qty - 1, index);
                                                        calculateTotalSale(menuInfo.cart);
                                                    }

                                                }}
                                            >-</span>
                                            &nbsp;
                                            <input
                                                type="number"
                                                className="searchat-qty-input"
                                                value={a.qty}
                                                style={{ width: "30px" }}
                                                onChange={(e) => updateCart("qty", +e.target.value, index)}
                                                min={1}
                                            />
                                            &nbsp;
                                            <span
                                                className="plus"
                                                style={{ background: design?.headerColor, width: "18px", height: "18px", borderRadius: "18px", padding: '0px', fontSize: "12px",fontWeight: "600" }}
                                                onClick={(e) => {
                                                    updateCart("qty", +menuInfo.cart[index].qty + 1, index);
                                                    calculateTotalSale(menuInfo.cart);
                                                }}
                                            >+</span>
                                        </div> : a.qty
                                }


                            </div>

                            <div style={{ width: "20%", display: 'inline-block', textAlign: "center" }}>
                                {approximate((a.sale * a.qty))} {menuInfo.cart_info.cart_currency}
                            </div>
                            {
                                 menuInfo.editable ?

                            <div style={{ width: "10%", display: 'inline-block', textAlign: "center" }}>
                            <i 
                                className="fas fa-edit"
                                style={{cursor:"pointer"}}
                                onClick = { () => 
                                    setItemToEditNotes({
                                        ...a,
                                        index:index
                                    })
                                }
                            ></i>

                           
                        </div>:null
                            }
                             {
                                    menuInfo.editable ?
                                    <div style={{ width: "10%", display: 'inline-block', textAlign: "center" }}>
                                           <i
                                            className="fas fa-trash"
                                            style={{ cursor: "pointer"}}
                                            onClick={() => {
                                                deleteItem(index);
                                                // calculateTotalSale(menuInfo.cart);
                                            }}
                                        ></i>
                                </div>
                                 : null}


                        </div>


                    )
                }
            </div>
            <div style={{ width: "100%", marginBottom: "5px", color: "gray", fontSize: "14px" }}>
                VAT : {approximate(menuInfo.total_price * menuInfo.cart_info.vat_ammount)} {menuInfo.cart_info.cart_currency}
            </div>
            <div style={{ width: "100%", marginBottom: "5px", color: "gray", fontSize: "14px" }}>
                Delievry : {menuInfo.cart_info.cart_delivery} {menuInfo.cart_info.cart_currency}
            </div>
            <div style={{ width: "100%", marginBottom: "10px", color: "gray", fontSize: "14px" }}>
                Payment Type: cash on deleviry
            </div>
            <div style={{ width: "100%", marginBottom: "10px", textAlign: "center", fontWeight: 500 }}>
                Total : {menuInfo.total_price} {menuInfo.cart_info.cart_currency}
            </div>
            <div
                style={{ height: "100%" }}
            >
                <div style={{ width: "50%", display: 'inline-block', fontWeight: "500", padding: "10px" }}>
                    <span
                        className="searchat-chatbot-button"
                        style={{ fontSize: "14px", padding: "6px", maxWidth: "200px", margin: 'auto',  background:design?.menuButtonBackgroundColor,
                        color: design?.menuButtonFontColor}}
                        onClick={() => {
                            eventDispatcher(
                                JSON.stringify({
                                    user_input: "CHECKOUT_REJECT_CART",
                                    bot_id: botInfo.bot_id
                                }),

                                (activities) => {
                                    activities.map(a => {
                                        setIncomingActivity(a);
                                    });                               
                                },
                                conversationID,
                                botInfo.bot_id
                            );
                          

                            setMenuInfo(undefined);
                            showTypingIndicator();
                        }}
                    >
                        Drop Cart
                    </span>
                </div>
                <div style={{ width: "50%", display: 'inline-block', fontWeight: "500", textAlign: "center", padding: "10px" }}>
                    <span
                        className="searchat-chatbot-button"
                        style={{ fontSize: "14px", padding: "6px", maxWidth: "200px", margin: 'auto',  background:design?.menuButtonBackgroundColor,
                        color: design?.menuButtonFontColor}}
                        onClick={() => {
                            eventDispatcher(
                                JSON.stringify({
                                    user_input: {
                                        modified_user_info: {
                                            ...menuInfo,
                                        },
                                        text: menuInfo.cart_info.phone_verified ? "CHECKOUT_CONFIRM_CART" : "CHECKOUT_CART_INFO"
                                    },
                                    bot_id: botInfo.bot_id
                                }),

                                (activities) => {
                                    activities.map(a => {
                                        setIncomingActivity(a);
                                    });                               
                                },
                                conversationID,
                                botInfo.bot_id
                            );
                    
                            setMenuInfo(undefined);
                            showTypingIndicator();
                        }}
                    >
                        Checkout
                    </span>
                </div>

            </div>
        </div>
    );
}

export default InvoiceMenu;