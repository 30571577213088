import React from "react";
import "../styles/standardCard.css";
import { eventDispatcher } from "../../../Scripts";


const LoadMore = ({
    cardData,
    showTypingIndicator,
    isFixedImageSize,
    design,
    botInfo,
    conversationID,
    setIncomingActivity,
    setIsTtsActive,
    voicePlugin
}) => {


    return (
        <div
            className="standard-card"
            style={{borderRadius:design?.cardBorderRadius,background:design?.cardBackgroundColor}}
            onClick = {() => {
                eventDispatcher(
                    JSON.stringify({
                        user_input: cardData.slicedText,
                        bot_id: botInfo.bot_id
                    }),
                      (activities) => {
                        activities.map(a => {
                            setIncomingActivity(a);
                        });
                        var text = "";
                        const text_arr = activities.filter(a => a.type === "message");
                        text_arr.forEach(a => {
                            text = text.concat(` ${a.text}`);
                        });
                        const event = new Event("dispatchTextToSpeech");
                        event.data = text.replace(new RegExp('<[^>]*>', 'g'), '');
                        event.showMuteIcon = () => setIsTtsActive(true);
                        event.hideMuteIcon = () => setIsTtsActive(false);
                        event.tts_gender = voicePlugin?.tts_gender;
                        window.dispatchEvent(event);
                    },
                    conversationID,
                    botInfo.bot_id
                );
               
              
                showTypingIndicator();
            }}
        >
            <div
                className="standard-card-desc"
            >
                <div 
                    style={{
                        color: design?.cardThemeColor ,
                        // background: design?.cardThemeColor,
                        borderRadius:"50%",
                        height: "225px",
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"center",
                        fontSize:"48px",
                        cursor:"pointer"
                    }}
                  
                >
                    <div style={{marginRight:"20px", display:"block",fontSize:"28px"}}>Load More</div>
               
                    <i style={{  fontSize:"48px"}} className="fas fa-angle-double-down"></i>
                </div>


            </div>
        </div>
    );

}


export default LoadMore;

