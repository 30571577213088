import React from "react";
import "../styles/userMessage.css";

const ar_regex = new RegExp("[\u0600-\u06ff]|[\u0750-\u077f]|[\ufb50-\ufc3f]|[\ufe70-\ufefc]");
const UserMessage = ({
    message,
    design,
    isAppearance
}) => {
    return (
        <div 
            style={{width:"100%",display:"flex",alignItems:"center",justifyContent:'flex-end'}} 
            onClick = {() => {
                if(isAppearance){
                    window.parent.postMessage(JSON.stringify({
                        type: "open_appearance_blocks",
                        block: "userMessageTable"
                    }), '*');
                }
            }}
        >
     
        <div 
            className="searchat-user-message-bubble" 
            style={{
                color:design?.userFontColor,
                background:design?.userBubbleBackground,
                borderRadius:design?.userBorderRadius,
                borderTopRightRadius: design?.userBubbleNub ? "0px" : design?.userBorderRadius ,
                textAlign: ar_regex.test(message) ? "right" : "left",
                direction:ar_regex.test(message) ? "rtl" : "ltr",
            }}
            
        >
          {message}
      </div>
      &nbsp;
      <div style={{width:"50px",display:"inline-block"}}>
            <img src={design?.userAvatar} style={{height:"40px",width:"40px",borderRadius:"40px"}}/>
        </div>
      </div>
    );
}   

export default UserMessage;