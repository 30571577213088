import React, { useState } from "react";
import { eventDispatcher } from "../../../Scripts";

const VerificationCodeMenu = ({
    menuInfo,
    setMenuInfo,
    showTypingIndicator,
    design,
    botConfig,
    botInfo,
    conversationID,
    setIncomingActivity,
    voicePlugin,
    setIsTtsActive,
}) => {

    console.log({
        menuInfo,
        setMenuInfo,
        showTypingIndicator,
        design,
        botConfig,
        botInfo,
        conversationID,
        setIncomingActivity,
        voicePlugin,
        setIsTtsActive,
    })

    const updateInfo = (entity, value) => {
        const menuInfoToUpd = { ...menuInfo };
        menuInfoToUpd[entity] = value;
        setMenuInfo(menuInfoToUpd);
    }
    
    const validate = () => {
        const missing = [];
        Object.keys(menuInfo).map(a => {
            if (typeof menuInfo[a] === 'string') {
                if (
                    !menuInfo[a].length
                ) {
                    missing.push(a);
                }
            }
        });
        return !missing.length && !isNaN(menuInfo.phone_verification_code);
    };

    return (
        <div
            className="searchat-chatbot-menu"
            style={{ height: "auto" ,background:design?.menuBackgroundColor}}
        >
             <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: 'flex-end',
                    fontSize:"18px",
                    color:"gray"
                }}
            >
                <i 
                    className="fas fa-window-close" 
                    style={{cursor:"pointer"}}
                    onClick = {() => setMenuInfo(undefined)}
                ></i>
            </div>
            <div style={{width:"100%", marginBottom:"20px"}}>
               {botConfig?.afterSendingVerificationCodeMessage}
            </div>
            <div style={{width:"100%", marginBottom:"20px"}}>
                <input
                    placeholder = "Verification Code"
                    style={{ border: "2px solid rgb(221, 221, 221)", padding: "5px", borderRadius: "5px", width: "100%" }}
                    value = {menuInfo.phone_verification_code}
                    onChange = {(event) => updateInfo("phone_verification_code",event.target.value)}
                />
            </div>
            <div style={{width:"100%"}}>
                <div style={{width:"50%",display:"inline-block"}}>
                    <span
                        className="searchat-chatbot-button"
                        style={{ fontSize: "14px", padding: "6px",   background:design?.menuButtonBackgroundColor,
                        color: design?.menuButtonFontColor}}
                        onClick={() => {
                            setMenuInfo(undefined);
                        }}
                    >
                        Cancel
                    </span>
                </div>
                <div style={{width:"50%",display:"inline-block"}}>
                    <span
                        className="searchat-chatbot-button"
                        style={{ background: validate() ?  design?.menuButtonBackgroundColor: "gray",fontSize: "14px", padding: "6px" , color: design?.menuButtonFontColor}}
                        disable={!validate()}
                        onClick={() => {
                            eventDispatcher(
                                JSON.stringify({
                                    user_input: { ...menuInfo, menu_type: undefined, text: "CHECK_VERIFICATION_CODE" },
                                    bot_id: botInfo.bot_id
                                }),  
                                (activities) => {
                                    activities.map(a => {
                                        setIncomingActivity(a);
                                    });
                                    var text = "";
                                    const text_arr = activities.filter(a => a.type === "message");
                                    text_arr.forEach(a => {
                                        text = text.concat(` ${a.text}`);
                                    });
                                    const event = new Event("dispatchTextToSpeech");
                                    event.data = text.replace(new RegExp('<[^>]*>', 'g'), '');
                                    event.showMuteIcon = () => setIsTtsActive(true);
                                    event.hideMuteIcon = () => setIsTtsActive(false);
                                    event.tts_gender = voicePlugin?.tts_gender;
                                    window.dispatchEvent(event);
                                },
                                conversationID,
                                botInfo.bot_id
                            );
                          
                            setMenuInfo(undefined);
                            showTypingIndicator();
                        }}
                    >
                        Submit
                    </span>
                </div>
            </div>
        </div>
    );
}

export default VerificationCodeMenu;