import React, { useState } from "react";

import { sttDispatcher, eventDispatcher } from "../../../Scripts";

const ListMenu = ({
    menuInfo,
    setMenuInfo,
    showTypingIndicator,
    botInfo,
    voiceLanguage,
    design,
    conversationID,
    setIncomingActivity,
    voicePlugin ,
    setIsTtsActive
}) => {

    const [selectedOption, setSelectedOption] = useState();
    return (
        <div
            className="searchat-chatbot-menu"
            style={{ height: "auto", maxHeight: "calc(100% - 165px)", background: design?.menuBackgroundColor, borderTopLeftRadius: "20px", borderTopRightRadius: "20px", bottom: "0px", zIndex: "2222222", padding: "10px 20px" }}
        >
            <div style={{ width: "100%", marginBottom: "10px", textAlign: "center", borderBottom: "1px solid #eee" }}>
                <i className="fas fa-times" style={{ float: "left", color: "gray", cursor: "pointer", fontSize: "16px" }}
                    onClick={() => {
                        setMenuInfo(undefined);
                    }}
                ></i>
                : يرجى الاختيار من القائمة
            </div>

            <div style={{ width: "100%",overflow:"auto" }}>
                {
                    menuInfo.options.map(option =>
                        <div style={{ width: "100%", marginBottom: "10px", textAlign: 'right', fontSize: "12px" }}>
                            {
                                option.text
                            }
                            <input
                                type="radio"
                                style={{ float: "left" }}

                                onChange={e => setSelectedOption(option.value)}
                                checked={option.value === selectedOption}
                            />
                        </div>
                    )
                }

            </div>

            <div style={{ width: "100%", marginTop: "10px", marginBottom: "5px" }}>
                <div style={{ width: "100%", textAlign: "center", display: 'inline-block', paddingLeft: "5px", margin: "auto" }}>
                    <span
                        className="searchat-chatbot-button"
                        style={{
                            background: selectedOption ? "#42D954" : "gray",
                            color: "white", fontSize: "16px", padding: "6px", width: "100%", maxWidth: "200px", margin: "auto", borderRadius: "2px"
                        }}
                        onClick={() => {
                            showTypingIndicator();
                            setMenuInfo(undefined);
                            eventDispatcher(
                                JSON.stringify({
                                    user_input: menuInfo.submit.postback,
                                    bot_id: botInfo.bot_id
                                }),

                                (activities) => {
                                    activities.map(a => {
                                        console.log("a", a)
                                        setIncomingActivity(a);
                                    });
                                    var text = "";
                                    const text_arr = activities.filter(a => a.type === "message");
                                    text_arr.forEach(a => {
                                        text = text.concat(` ${a.text}`);
                                    });
                                    const event = new Event("dispatchTextToSpeech");
                                    event.data = text.replace(new RegExp('<[^>]*>', 'g'), '');
                                    event.showMuteIcon = () => setIsTtsActive(true);
                                    event.hideMuteIcon = () => setIsTtsActive(false);
                                    event.tts_gender = voicePlugin?.tts_gender;
                                    window.dispatchEvent(event);
                                },
                                conversationID,
                                botInfo.bot_id
                            );
                        }}
                    >
                        {menuInfo.submit.label}
                    </span>
                </div>
            </div>
        </div>
    );
}

export default ListMenu;