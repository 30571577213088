import React, { useState, memo } from "react";
import "../styles/standardCard.css";
import Button from "../../Button";
import { eventDispatcher, generatetRandomId } from "../../../Scripts";

const SarQr = ({
  cardData,
  showTypingIndicator,
  isFixedImageSize,
  design,
  botInfo,
  setMenuInfo,
  setIncomingActivity,
  conversationID,
  setIsTtsActive,
  voicePlugin,
}) => {
  return (
    <div
      className="standard-card"
      style={{
        borderRadius: design?.cardBorderRadius,
      }}
    >
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "30% 70%",
          padding: "10px",
        }}
      >
        <div>
          <div
            style={{ width: "100%", height: "auto", display: "inline-block" }}
          >
            <img
              src={cardData?.qr_code}
              style={{ width: "100%", objectFit: "cover" }}
            />
          </div>
        </div>
        <div>
          <div style={{ display: "flex", height: "50px", padding: "20px" }}>
            <div style={{ flex: "none", width: "30%" }}>
              <div style={{ textAlign: "center" }}>
                <p style={{ textAlign: "center" }}>
                  {cardData.settings?.data?.from?.name_ar}
                </p>
              </div>
            </div>
            <div style={{ flexGrow: 1 }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 283 34"
                className="transform-gpu -scale-x-1"
              >
                <defs></defs>
                <path
                  fill="#00758d"
                  d="M170.56,10h0c5.7,0,10.21,3.1,12.31,4.86L175,14.16A19.26,19.26,0,0,1,164.77,10Zm14.5,5.21c-.24-.26-6-6.45-14.5-6.45h-8.81l1,1a20,20,0,0,0,12.15,5.64l11.27.94ZM191.71,28a2.09,2.09,0,0,0,2-1.69l.58-2.9a2.68,2.68,0,0,0-.28-1.8c-.33-.6-.74-1.32-1-1.75h0l-.2-.32a30.81,30.81,0,0,0-26.1-14.39H106.46a2.58,2.58,0,0,0-2.57,2.57V25.5h1.24V7.72a1.33,1.33,0,0,1,1.33-1.33h60.32a29.57,29.57,0,0,1,25,13.79l0,0c.12.22.7,1.22,1.12,2a1.43,1.43,0,0,1,.15,1l-.57,2.9a.86.86,0,0,1-.84.69H3.93V28H191.71Zm-.08,2.52a4.67,4.67,0,0,0,4.56-3.75l.54-2.73a5.67,5.67,0,0,0-.61-3.85c-2.91-5.26-11.6-17.51-29.36-17.51H106.33a5,5,0,0,0-5,5V25.51h1.24V7.65a3.73,3.73,0,0,1,3.72-3.73h60.43c17.1,0,25.47,11.81,28.28,16.88a4.43,4.43,0,0,1,.47,3L195,26.54a3.41,3.41,0,0,1-3.34,2.75H3.93v1.24Zm5.84-3.76-.05.25a5.6,5.6,0,0,1-.28,1H280V26.77Zm-1.05,2.52H280v1.24H195.25a5.74,5.74,0,0,0,1.17-1.24"
                ></path>
              </svg>
            </div>

            <div style={{ flex: "none", width: "30%" }}>
              <p style={{ textAlign: "center" }}>
                {cardData.settings?.data?.to?.name_ar}
              </p>
            </div>
          </div>
          <br />

          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingTop: "1rem",
              paddingBottom: "1rem",
              paddingLeft: "1.5rem",
              paddingRight: "1.5rem",
              flexDirection: "row",
            }}
          >
            <img src="https://storage.botforce.ai/Bots/SAR-191/cardsImages/Screen%20Shot%202022-02-07%20at%2012.54.38%20PM.jpg" />
          </div>
        </div>
      </div>

      <div style={{ width: "100%", padding: "10px" }}>
        <Button
          textColor={design?.cardButtonFontColor}
          backgroundColor={design?.cardThemeColor}
          label="تأكيد الحجز"
          customStyles={{ marginBottom: "5px" }}
          onclick={() => {
            eventDispatcher(
              JSON.stringify({
                user_input: "SUBMIT_ALL_2",
                bot_id: botInfo.bot_id,
              }),
              (activities) => {
                activities.map((a) => {
                  console.log("a", a);
                  setIncomingActivity(a);
                });
                var text = "";
                const text_arr = activities.filter((a) => a.type === "message");
                text_arr.forEach((a) => {
                  text = text.concat(` ${a.text}`);
                });
                const event = new Event("dispatchTextToSpeech");
                event.data = text.replace(new RegExp("<[^>]*>", "g"), "");
                event.showMuteIcon = () => setIsTtsActive(true);
                event.hideMuteIcon = () => setIsTtsActive(false);
                event.tts_gender = voicePlugin?.tts_gender;
                window.dispatchEvent(event);
              },
              conversationID,
              botInfo.bot_id
            );

            showTypingIndicator();
          }}
        />
      </div>
    </div>
  );
};

export default memo(SarQr);
