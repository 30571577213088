import React, { useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsMore from 'highcharts/highcharts-more';

HighchartsMore(Highcharts);


const General = ({
    settings,     
    xData,
    yData,
    chartEntities
}) => {
  return (
    <HighchartsReact
      highcharts={Highcharts}
      allowChartUpdate={true}
      options={{
        ...chartEntities.parent 
      }}
    />
  );
};

export default React.memo(General);
