import React from "react";
import "../styles/standardCard.css";
import Button from "../../Button";
import { eventDispatcher, generatetRandomId } from "../../../Scripts";


const ar_regex = new RegExp("[\u0600-\u06ff]|[\u0750-\u077f]|[\ufb50-\ufc3f]|[\ufe70-\ufefc]");
const SscCard = ({
    cardData,
    showTypingIndicator,
    isFixedImageSize,
    design,
    botInfo,
    setMenuInfo,
    setIncomingActivity,
    conversationID,
    setIsTtsActive,
    voicePlugin,
    pushToActivitiesHistory
}) => {


    return (
        <div
            className="standard-card"
            style={{
                borderRadius: design?.cardBorderRadius,
                
            }}
        >

            <div className="standard-card-img-cover">
                {
                    Boolean(cardData?.url) ?
                        <div style={{ width: "100%" }}>
                            <video
                                controls
                                autoPlay
                                muted
                                style={{ width: "100%" }}
                            >
                                <source
                                    src={cardData?.url}
                                ></source>
                            </video>
                        </div> : null
                }
            </div>


                <div
                 style={{display:"block",width:"100%"}}
                >
                        {
                cardData.files?.map((file,index) =>
                <div style={{   display: "inline-block", width: cardData.files?.length === (index +1) && (cardData.files?.length%2 ===1) ? "100%": "50%",padding:"0px",paddingRight:"2px", paddingLeft: "2px"}}>
   <div
                        className="standard-card"
                        style={{
                            borderRadius: "5px",
                            padding:"0px",
                            marginTop:"3px",
                            marginBottom:"3px"
                        }}

                    >
                        <div 
                            style={{height: "130px", position:"relative", cursor:"pointer"}}
                            onClick = {() =>   window.open(file.url, "_blank")}
                        >
                          <img src={file.url} />
                          <span
                           
                            style={{color:design?.headerColor, fontSize: "12px", fontWeight:700, width:"100%", textDecoration: "none", position:"absolute", bottom:"0px", left:"0px", background:"white", padding:"5px", textAlign:"right"}}
                         >
                            {file.title}
                        </span>
                        </div>
                        
                       

                    </div>
                </div>
                 
                )
            }

                </div>
        



        </div>
    );

}


export default SscCard;

