import React, { useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";




const approximate = (value) => Math.floor(value * 100) / 100;

const Column = ({
    settings,     
    xData,
    yData,
    chartEntities
}) => {

    console.log("chartEntities",chartEntities)

  return (
    <HighchartsReact
      highcharts={Highcharts}
      allowChartUpdate={true}
      options={{
        chart: {
          type: "column",
        },
        accessibility: {
          announceNewData: {
            enabled: true,
          },
        },   
        ...chartEntities.parent ,
        plotOptions: {
            column: {
              borderColor: "transparent",
              borderRadius: 5,
              shadow: true,
              depth: 100,
              dataLabels: {
                enabled: true,
                formatter: function () {
                  return this.y + "%";
                },
              },
            },
          }
      }}
    />
  );
};

export default React.memo(Column);
