import React, { useState } from "react";
import {
  Line,
  Column,
  General
} from "./subComponents";


const Chart = ({ design, id, key, settings, chartEntities }) => {
  const [xAxis, _] = useState(settings?.x);
  const [yAxis, __] = useState(settings?.y);
  const [xData, ___] = useState(chartEntities.parent[xAxis]);
  const [yData, ____] = useState(chartEntities.parent[yAxis]);

  console.log("xData", xData);
  console.log("yData", yData);
  console.log("xAxis", xAxis);
  console.log("yAxis", yAxis);

  return (
    <div
      className="standard-card"
      style={{
        maxWidth: "100%",
        borderRadius: "20px",
        background: "transparent",
      }}
      id={id}
      key={key}
    >


      {settings?.graph === "line" ? (
        <Line
          design={design}
          settings={settings}
          xData={xData}
          yData={yData}
        />
      ) : null}
      {settings?.graph === "column" ? (
        <Column
          design={design}
          settings={settings}
          xData={xData}
          yData={yData}
          chartEntities={chartEntities}
        />
      ) : null}

      {settings?.graph === "general" ? (
        <General
          design={design}
          settings={settings}
          xData={xData}
          yData={yData}
          chartEntities={chartEntities}
        />
      ) : null}

    </div>
  );
};

export default Chart;
