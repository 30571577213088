import React, { useState, memo } from "react";
import "../styles/standardCard.css";
import Button from "../../Button";
import { eventDispatcher, generatetRandomId } from "../../../Scripts";

const ar_regex = new RegExp("[\u0600-\u06ff]|[\u0750-\u077f]|[\ufb50-\ufc3f]|[\ufe70-\ufefc]");

const isEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  
    if (re.test(String(email).toLowerCase())) {
      return email;
    } else {
      return false;
    }
  };

const FormCard = ({
    cardData,
    showTypingIndicator,
    isFixedImageSize,
    design,
    botInfo,
    setMenuInfo,
    setIncomingActivity,
    conversationID,
    setIsTtsActive ,
    voicePlugin
}) => {

    const [formData, setFormData] = useState(cardData.settings.initData);


    const updateState = (entity, value) => {
        const formDataToUpd = { ...formData };
        formDataToUpd[entity] = value;
        setFormData(formDataToUpd);
    }

    const validate = () => {
        var isALLfilled = true;
        Object.keys(formData||{}).map(key => {
            if(!Boolean(formData[key])){
                isALLfilled = false;
            }
        });
        return isALLfilled;
    }
    return (
        <div
            className="standard-card"
            style={{
                borderRadius: design?.cardBorderRadius
            }}

        >
            <div
                className="standard-card-desc"
                style={{ background: design?.cardBackgroundColor }}
            >
                <div
                    className="standard-card-heading"
                    style={{textAlign:  ar_regex.test(cardData?.settings?.title) ? "right" : "left"}}

                >
                    {
                        cardData.settings.title
                    }
                </div>

                <br />

             

                {
                    cardData?.settings?.inputs?.map((input,index) =>
                        <div style={{ marginBottom: '10px',textAlign:  ar_regex.test(cardData?.settings?.title) ? "right" : "left" }} key={`form-card-input-${index}`}>
                            {input.input_label}
                            <br/>
                            {
                                input.input_type === 'email' && !isEmail(formData[input.entity]) && Boolean(formData[input.entity]) ? 
                                <span style={{color:"red"}}>الرجاء كتابة بريد الكتروني صحيح</span>: null
                            }
                            <input
                                style={{
                                    width: "100%",
                                    padding: "5px",
                                    padding: '10px 12px',
                                    marginBottom: '5px',
                                    border: '1px solid #e5e5e5',
                                    borderBottom: '2px solid #ddd',
                                    background: '#f2f2f2',
                                    color: '#555',
                                    zIndex: 1000000       
                                }}
                                min={input.min}   
                                placeholder={input.input_label}
                                type={input.input_type}
                                value={formData[input.entity]}
                                onChange={(e) => {
                                  
                                    updateState(input.entity, e.target.value)
                                }}
                            />
                        </div>
                    )
                }
                <Button
                    textColor={design?.cardButtonFontColor}
                    backgroundColor={validate() ? design?.cardThemeColor: 'gray'}
                    label={cardData.settings.submit.label}
                    disabled = {!validate()}
                    customStyles={{ marginBottom: "5px" }}
                    onclick={() => {

                        eventDispatcher(
                            JSON.stringify({
                                user_input: {
                                    ...formData,
                                    postback: cardData.settings.submit.postback
                                },
                                bot_id: botInfo.bot_id
                            }),
                            (activities) => {
                                activities.map(a => {
                                    console.log("a", a)
                                    setIncomingActivity(a);
                                });
                                var text = "";
                                const text_arr = activities.filter(a => a.type === "message");
                                text_arr.forEach(a => {
                                    text = text.concat(` ${a.text}`);
                                });
                                const event = new Event("dispatchTextToSpeech");
                                event.data = text.replace(new RegExp('<[^>]*>', 'g'), '');
                                event.showMuteIcon = () => setIsTtsActive(true);
                                event.hideMuteIcon = () => setIsTtsActive(false);
                                event.tts_gender = voicePlugin?.tts_gender;
                                window.dispatchEvent(event);
                            },
                            conversationID,
                            botInfo.bot_id
                        );

                        showTypingIndicator();
                    }}
                />
            </div>



        </div>

    );

}


export default memo(FormCard);

