import React from "react";
import "../styles/botMessage.css";
import showdown from 'showdown';

const ar_regex = new RegExp("[\u0600-\u06ff]|[\u0750-\u077f]|[\ufb50-\ufc3f]|[\ufe70-\ufefc]");
const markdownToHtmlConverter = value => {
    var converter = new showdown.Converter();
    return converter.makeHtml(value);
  };

  var CHARCODE_SHADDA = 1617;
  var CHARCODE_SUKOON = 1618;
  var CHARCODE_SUPERSCRIPT_ALIF = 1648;
  var CHARCODE_TATWEEL = 1600;
  var CHARCODE_ALIF = 1575;
  
  function isCharTashkeel(letter) {
    if (typeof (letter) == "undefined" || letter == null)
      return false;
  
    var code = letter.charCodeAt(0);
    //1648 - superscript alif
    //1619 - madd: ~
    return (code == CHARCODE_TATWEEL || code == CHARCODE_SUPERSCRIPT_ALIF || code >= 1612 && code <= 1631); //tashkeel
  }
  
  function stripTashkeel(input) {
    var output = "";
    //todo consider using a stringbuilder to improve performance
    for (var i = 0; i < input.length; i++) {
      var letter = input.charAt(i);
      if (!isCharTashkeel(letter)) //tashkeel
        output += letter;
    }
  
  
    return output;
  }
const BotMesssage = ({
    message,
    design,
    isAppearance 
}) => {
    return (
        <div 
            style={{width:"100%",display:"flex",alignItems:"center"}} 
            onClick = {() => {
                if(isAppearance){
                    window.parent.postMessage(JSON.stringify({
                        type: "open_appearance_blocks",
                        block: "botMessageTable"
                    }), '*');
                }
            }}
        >
            <div style={{width:"50px",display:"inline-block"}}>
                <img src={design?.botAvatar} style={{height:"40px",width:"40px",borderRadius:"40px"}}/>
            </div>
             <div 
                className="searchat-bot-message-bubble" 
                style={{
                    display:"inline-block",
                    background:design?.botBubbleBackground,
                    borderRadius:design?.botBorderRadius,
                    borderTopLeftRadius: design?.botBubbleNub ? "0px" : design?.botBorderRadius ,
                    color: design?.botFontColor,
                    fontSize:"16px",
                    textAlign: ar_regex.test(message) ? "right" : "left",
                    direction:ar_regex.test(message) ? "rtl" : "ltr",
                }}   
                dangerouslySetInnerHTML={{
                    __html: 
                    stripTashkeel(message)
                    
                  }}          
            >
             
            </div>
      </div>
    );
      
       
}   

export default BotMesssage;