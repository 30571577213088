import React from "react";

const Sa3iTrackingCard = ({
    cardData,
    isFixedImageSize,
    design,
    botInfo
}) => {

    return (
        <div
            className="standard-card"
            style={{borderRadius:design?.cardBorderRadius,width:"auto", maxWidth:"100%"}}
        >
        <table>
            <tr>
            <th style={{width:"25%"}}>Event</th>
                <th style={{width:"25%"}}>Country</th>
                <th style={{width:"25%"}}>Location</th>
                <th style={{width:"25%"}}>Date</th>

            </tr>
            {
                cardData.tracking_results.map(a =>
                    <tr>

                        <td style={{width:"25%",lineBreak:"anywhere"}}>{a.EventDesc[0]}</td>
                        <td style={{width:"25%",lineBreak:"anywhere"}}>{a.Country[0]}</td>
                        <td style={{width:"25%",lineBreak:"anywhere"}}>{a.Location[0]}</td>
                        <td style={{width:"25%",lineBreak:"anywhere"}}>{a.EventDate[0]}</td>
                       
                    </tr>
                )
            }
        </table>
           
        </div>
    )

}


export default Sa3iTrackingCard;